<template>
  <div class="experiment-list">

    <b-container>
      <div class="section-title">Experiments, Thoughts, &amp; Fun</div>
      <b-row>
        <b-col xs="12" lg="6" xl="4" v-for="experiment in projects" class="project-card-wrapper">
          <ExpCard
            :img="experiment.img"
            :title="experiment.title"
            :description="experiment.description"
            :source="experiment.source"
            :link="experiment.link"
          >
        </ExpCard>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import ExpCard from '@/components/ExpCard.vue'

export default {
  name: 'Experiments',
  components: {
    ExpCard
  },
  data() {
    return {
      projects: [
        {
          id: 0,
          title: 'Spatial Weather App',
          img: 'ml-weather.jpg',
          description: 'A weather app I designed and developed using Magic Leap’s JavaScript language—MagicScript. ',
          source: null,
          link: null,
        },
        {
          id: 1,
          title: 'Sketch Text Style Script',
          img: 'sketch-text-scale.png',
          description: 'A script built for Sketch to easily create text styles at scale. No longer needing to hand write each separate color and alignment instance. ',
          source: 'GitHub',
          link: 'https://github.com/JohnathonSimmons/Sketch-Text-Style-Generator',
        },
        {
          id: 2,
          title: 'MagicScript Transform Library',
          img: 'ml-transform.png',
          description: 'A helper library to apply curves and a transform for MagicScript prototyping. Allows a prototype to have animation without needing to draw each frame.',
          source: 'GitHub',
          link: 'https://github.com/JohnathonSimmons/magicscript-transform-helpers',
        },
        {
          id: 3,
          title: 'Type Scale Calculator',
          img: 'typescale.png',
          description: 'There are many type scale calculators, but I built one myself to understand the calculations being used and why the scale produced the sizes they did. ',
          source: 'GitHub',
          link: 'https://github.com/JohnathonSimmons/text-scale',
        },
        {
          id: 4,
          title: 'Lettering Studies',
          img: 'lettering.png',
          description: 'Always interested in lettering, here a few studies or different letterforms. You can also view more on my Dribbble page.',
          source: 'Dribbble',
          link: 'https://dribbble.com/Johnathon',
        },
        {
          id: 5,
          title: 'Netflix and Chill',
          img: 'netflix.jpg',
          description: 'I contributed to an article about the Netflix interface. Exploring the idea of making decision of your next binging experience more similar to a dating app. ',
          source: 'FastCo',
          link: 'https://www.fastcompany.com/90311047/3-brilliant-ways-netflix-can-fix-home-screen-this-year',
        },

      ],
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.project-card-wrapper
  margin-bottom: 56px
</style>
