<template>
  <div id="app">
    <div class="password-prompt" v-if="data.password !== data.passwordValidate">

      <b-container>
        <b-row>
          <b-col>
            <h2>Johnathon Simmons</h2>
            <p></p>
            <p>UX / UI / AR Design</p>
            <form class="prompt-form">
              <label for="password">Password</label>
              <input type="password" v-model="data.passwordInput"/>
              <button type="submit" class="btn btn-primary" @click="data.passwordValidate = data.passwordInput; errorMessage = true">Enter</button>
            </form>
          </b-col>
        </b-row>

        <p class="mt-3 text-center" style="color: #ff0033" v-if="errorMessage">Uh oh, that doesn't seem right. Try again?</p>
      </b-container>

    </div>
    <div class="portfolio" v-if="data.password == data.passwordValidate">
      <b-navbar sticky toggleable="lg" type="light" variant="light">
        <div class="container">
          <router-link to="/">
            <b-navbar-brand>Johnathon</b-navbar-brand>
          </router-link>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">

              <b-navbar-nav>
                <router-link to="/">Work</router-link>
                <router-link to="/about">About</router-link>
              </b-navbar-nav>

            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
      <router-view/>
      <footer>
        <b-container>
          <p>2021 &copy; Johnathon Simmons</p>
        </b-container>
      </footer>
    </div>
  </div>
</template>

<script>
import { data } from '@/data/data.js'

export default {
  data () {
    return {
      data: data
    }
  }
}
</script>


<style lang="sass">

img
  max-width: 100%

#app
  font-family: 'Lato', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


#nav
  padding: 30px

  a
    font-weight: bold
    color: #2c3e50

    &.router-link-exact-active
      color: #42b983

footer
  background-color: black
  color: white
  width: 100%
  padding: 1.5rem 0
  overflow: hidden
  margin-top: 120px

footer > p
  font-family: 'Space Mono'
  font-size: 1rem

.navbar-nav
  a
    color: black
    font-family: 'Space Mono', monospace
    margin-left: 2rem

@media (max-width: 991.98px)
  .navbar-expand-lg > .container
    padding: 0 15px !important

</style>
