<template>
  <div class="hero">
    <b-container fluid>
      <b-container>
        <b-row>
          <b-col cols="12" lg="6" offset-lg="1" id="home-hero-img">
            <img src="@/img/hero.png"/>
          </b-col>
          <b-col cols="12" lg="4" offset-lg="1">
            <h1>Designing Products<br/>and Solutions</h1>
            <p>A designer with 5+ years of experience in emerging technologies, SaaS solutions, healthcare, and the Internet of Things. With interests in lettering, coding, and craft.</p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.hero
  background-color: #fafafa
  padding: 96px 0
  @media screen and (min-width: 991.98px )
      height: 75vh
      max-height: 700px

  .container, .container-fluid, .row
    height: 100%
    display: flex
    align-items: center

h1, .h1
  margin-bottom: 24px

@media (max-width: 991.98px)
  #home-hero-img
    margin-bottom: 96px

</style>
