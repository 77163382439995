let projects = [
  {
    id: 1,
    client: 'Freeze',
    title: 'First of its kind web app for cryo storage',
    img: 'freeze-hero.png',
    tags: ['Healthcare', 'Web App'],
    description: 'Designing a web based product, with a successful exit.',
    logo:'logos/freeze.png',
    route:'/freeze'
  },
  {
    id: 2,
    client: 'Scenes',
    title: 'Enabling rapid prototyping for AR',
    img: 'scenes-hero.jpg',
    tags: ['Augmented Reality', 'Prototyping'],
    description: 'I created a prototyping product for quick iteration of AR designs.',
    logo: 'logos/scenes.png',
    route:'/scenes'
  },
  // {
  //   id: 3,
  //   client: 'Magic Leap',
  //   title: 'Market research to drive prioritization',
  //   img: 'ml-research-hero.png',
  //   tags: ['Augmented Reality', 'Research'],
  //   description: 'I developed a prototyping product for the quick iteration of AR designs.',
  //   logo: 'logos/ml.png',
  //   route:'/'
  // },
  {
    id: 4,
    client: 'United Rentals',
    title: 'Mobile management of large equipment',
    img: 'ur-hero.jpg',
    tags: ['Mobile'],
    description: 'I conducted research and designing a mobile app, for the largest equipment marketplace in North America.',
    logo: 'logos/ur.png',
    route:'/ur'
  },
  {
    id: 5,
    client: 'Magic Leap',
    title: 'Spatialized location mapping',
    img: 'ml-mapping-hero.jpg',
    tags: ['Augmented Reality'],
    description: 'I designed applications and processes for creating spatial reconstructions of a location.',
    logo: 'logos/ml.png',
    route:'/mapping'
  },
  {
    id: 6,
    client: 'Magic Leap',
    title: 'Defining AR design and interaction guidelines',
    img: 'ml-guides-hero.jpg',
    tags: ['Augmented Reality'],
    description: 'Design and developer documents describing best practices for spatial computing and interacting with a Magic Leap device.',
    logo: 'logos/ml.png',
    route:'/ml-guides'
  },
  {
    id: 0,
    client: 'Magic Leap',
    title: 'LED indicators as a hardware interface',
    img: 'ml-led-hero.gif',
    tags: ['Hardware Interface', 'Motion'],
    description: 'Designing LED patterns to inform users of hardware.',
    logo: 'logos/ml.png',
    route: '/leds'
  },
  // {
  //   id: 7,
  //   client: 'Magic Leap',
  //   title: 'Building a 3D desktop environment',
  //   img: 'ml-prism-hero.png',
  //   tags: ['Augmented Reality'],
  //   description: 'Designing an immersive desktop environment for wearable AR.',
  //   logo: 'logos/ml.png',
  //   route:'/'
  // },
]
export { projects }
