<template>
  <div class="project-card" @click="onClick">
    <img :src="require('@/img/' + img + '')" class="project-hero"/>

    <ul v-if="tags" class="project-tags">
      <li v-for="tag in tags">{{ tag }}</li>
    </ul>
    <div class="border-bottom"></div>

    <h3 class="project-title">{{ title }}</h3>
    <!-- <p class="project-description">
      {{ description }}
    </p> -->
    <router-link :to="route" class="link-display">See Project</router-link>
    <img :src="require('@/img/' + logo + '')" class="project-logo"/>


  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    img: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: false
    },
    route: {
      type: String,
      required: false
    },
  },
  methods: {
    onClick: function(event) {
      this.$router.push(this.route)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.project-card
  position: relative
  padding: 20px 20px 88px 20px
  cursor: pointer
  height: 100%
  transition: all ease 1s

  background-color: #FAFAFA

  &:hover
    //background-color: red
    .border-bottom
      width: 100%
    .project-hero
      transform: scale(1.01)

a
  color: black

.project-hero
  width: calc(100% + 40px) !important
  max-width: calc(100% + 40px) !important
  margin-left: -20px
  margin-top: -20px
  transition: all ease .5s
  border-radius: 0



.descriptor-block
  transition: all ease .5s

.project-tags
  position: relative
  list-style: none
  margin: 0
  padding: 0
  width: 100%
  border-bottom: 1px solid #C4C4C4
  font-size: .75rem
  padding: 20px 0
  margin-top: 12px
  transition: all ease .3s
  &:after
    content: ''
    height: 1px
    background-color: black
    width: 0
    position: absolute
    bottom: -1px
    left: 0
  li
    font-family: 'Space Mono', monospace
    font-size: .75rem
    display: inline-block
    height: auto
    &:not(:first-of-type)
      margin-left: 12px
      padding-left: 12px
      border-left: 1px solid #C4C4C4

.border-bottom
  width: 0%
  height: 2px
  background-color: black
  transition: all ease .5s
  margin: 0 auto

.project-title
  margin-top: 20px
  width: 75%
  font-size: 1.5rem
  font-weight: bold
  line-height: 1.5

.project-description
  margin-top: 20px
  width: 75%
  font-size: 1rem
  line-height: 1.5

.link-display
  font-family: 'Space Mono', monospace
  font-size: .875rem
  margin-top: 24px
  &:after
    content: '→'


.project-logo
  margin-top: 32px
  max-height: 32px
  position: absolute
  bottom: 32px
  left: 20px

</style>
