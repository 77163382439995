<template>
  <div class="footer">
    <b-container>
      <b-row>
        <b-col>
          <p>&copy; 2021 Johnathon Simmons</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">


.footer > p
  font-family: 'Space Mono'
  font-size: 1rem


</style>
