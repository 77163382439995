<template>
  <div class="skills">
    <b-container>
      <div class="section-title">Capabilities</div>
      <b-row>
        <b-col xs="12" lg="3">
          <h3 class="cap-type">Research &amp;<br/>User Empathy</h3>
          <p>Understanding users through interviews and analytical analysis to understand their needs. </p>
        </b-col>
        <b-col xs="12" lg="3" offset-lg="1">
          <h3 class="cap-type">Solution<br/>driven design</h3>
          <p>Sketches, wires, mockups, and documentation, designed to solve business and user problems.</p>
        </b-col>
        <b-col xs="12" lg="3" offset-lg="1">
          <h3 class="cap-type">Rich prototyping<br/>&amp; iteration</h3>
          <p>Using industry standard prototyping tools, and creating my own, to stand up ideas early and often. </p>
        </b-col>
      </b-row>

    </b-container>
    <b-container class="tools">
      <b-row>
        <b-col xs="12" xl="3">
          <h4>Tools</h4>
          <p class="tools-description">Always looking for the right tool for the job, but here are a few I use frequently. </p>
        </b-col>
        <b-col xs="12" md="6" xl="3">
          <ul>
            <li>Sketch</li>
            <li>Figma</li>
            <li>InVision</li>
            <li>Cinema4D</li>
          </ul>
        </b-col>
        <b-col xs="12" md="6" xl="3">
          <ul>
            <li>Photoshop</li>
            <li>Illustrator</li>
            <li>Principle</li>
            <li>Framer</li>
          </ul>
        </b-col>
        <b-col xs="12" md="6" xl="3">
          <ul>
            <li>CSS (&amp; Sass)</li>
            <li>Javascript</li>
            <li>Vue</li>
            <li>Blender</li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Skills',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.skills
  padding-bottom: 64px
  background-color: #FAFAFA

.h4, h4
  font-size: 1rem
  font-family: 'Space Mono', monospace
  line-height: 3

.tools
  border-top: 1px solid #CACACA
  padding-top: 64px
  margin-top: 64px

.tools-description
  margin-top: 2rem
  width: 80%

ul
  font-family: 'Space Mono', monospace
  list-style: none
  margin: 0
  padding: 0
  line-height: 3

</style>
