<template>
  <div class="project-list">

    <b-container>
      <div class="section-title" id="work">Work</div>
      <b-row>
        <b-col xs="12" lg="6" xl="4" v-for="project in projects" class="project-card-wrapper">
          <ProjectCard
            :img="project.img"
            :tags="project.tags"
            :title="project.title"
            :description="project.description"
            :logo="project.logo"
            :route="project.route"
          >
          </ProjectCard>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'
import { projects } from '@/data/projects.js'

console.log('project list: ' + projects)

export default {
  name: 'ProjectList',
  components: {
    ProjectCard
  },
  data() {
    return {
      projects: projects
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.project-card-wrapper
  margin-bottom: 56px
</style>
