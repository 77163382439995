<template>
  <div class="home">
    <Hero></Hero>
    <ProjectList></ProjectList>
    <Skills></Skills>
    <Experiments></Experiments>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'

import ProjectList from '@/components/ProjectList.vue'

import Skills from '@/components/Skills.vue'

import Experiments from '@/components/Experiments.vue'

//import text from '@/articles/freeze.md'


export default {
  name: 'Home',
  data() {
    return {
      input: '# hello',
    }
  },
  components: {
    Hero, ProjectList, Skills, Experiments
  },
  computed: {
    // compiledMarkdown: function() {
    //   let mark = marked(text, {sanitize: true})
    //   console.log(mark)
    //   return mark;
    // }
  }
}
</script>
