import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/leds',
    name: 'LEDs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../work/LED.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/freeze',
    name: 'Freeze',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../work/Freeze.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/scenes',
    name: 'Scenes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../work/Scenes.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ur',
    name: 'UR',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../work/UR.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mapping',
    name: 'Mapping',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../work/Mapping.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ml-guides',
    name: 'MLGuides',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../work/MLGuides.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
//   beforeEach (to, from, next) => {
//   if (to.meta.requiresAuth) {
//     if (!storageHelper.getItem('user-password')) next('/protected')
//     else next()
//   } else next()
// }
})

export default router
