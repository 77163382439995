<template>
  <div class="experiment-card">
    <img :src="require('@/img/experiments/' + img + '')" class="card-hero"/>

    <h3 class="card-title">{{ title }}</h3>
    <p class="card-description">
      {{ description }}
    </p>
    <a class="link-display" :href="link" v-if="source && link" target="_blank">
      See on {{ source }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: false
    },
    link: {
      type: String,
      required: false
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.project-card
  position: relative
  padding-bottom: 56px
  cursor: pointer
  height: 100%
  transition: all ease 1s

  &:hover
    .project-hero
      transform: scale(1.01)
      border-radius: 12px

.card-hero
  transition: all ease .5s
  border-radius: 0

.card-title
  margin-top: 20px
  font-size: 1.5rem
  font-weight: bold
  line-height: 1.5

.card-description
  margin-top: 20px
  font-size: 1rem
  line-height: 1.5

.link-display
  font-family: 'Space Mono', monospace
  font-size: .875rem
  margin-top: 24px
  &:after
    content: '→'

</style>
